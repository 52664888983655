<template>
  <div>
    <trabajador-form
      :is-toggle-sidebar.sync="isToggleSidebar"
      :form-type="formType"
      :data-edit="dataEdit"
      @refetch-data="refetchData"
      @error-data="showError"
    />

    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="query"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
              <b-button
                variant="primary"
                @click="openForm('new')"
              >
                <span class="text-nowrap">Agregar</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="fields"
        primary-key="idTrabajador"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              variant="primary"
              class="align-middle"
            />
            <strong class="ml-1">Cargando...</strong>
          </div>
        </template>
        <!-- Column: Trabajador -->
        <template #cell(nombre)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="50"
                :src="`data:image/jpeg;base64,${data.item.foto}`"
                :text="avatarText(`${data.item.nombre} ${data.item.apellidoPaterno}`)"
                :variant="`light-${getColorAvatar()}`"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.nombre }} {{ data.item.apellidoPaterno }} {{ data.item.apellidoMaterno }}
            </span>
            <small class="text-muted">{{ data.item.tipoDocumento.abreviatura }}  {{ data.item.documento }} | EDAD: {{ getEdad(data.item.fechaNacimiento) }}</small>
          </b-media>
        </template>

        <!-- cARGO -->
        <template #cell(cargo)="data">
          <div class="d-flex align-items-center">
            <div>
              <div class="font-small-3 font-weight-bolder">
                {{ data.item.cargo }}
              </div>
              <div class="font-small-2 text-muted">
                Fecha Ingreso: {{ data.item.fechaIngreso }}
              </div>
            </div>
          </div>
        </template>

        <template #cell(empresa)="data">
          <div class="d-flex align-items-center">
            <div>
              <div class="font-small-3 font-weight-bolder">
                {{ data.item.empresa.razonSocial }}
              </div>
              <div class="font-small-2 text-muted">
                RUC: {{ data.item.empresa.ruc }}
              </div>
            </div>
          </div>
        </template>

        <!-- Redes Sociales -->
        <template #cell(facebook)="data">
          <b-link
            v-if="data.item.celular != '' && data.item.celular != null"
            v-b-tooltip.hover.top="`Celular: ${data.item.celular}`"
            :href="`tel:${data.item.celular}`"
            target="_blank"
            class="font-weight-bold"
          >
            <b-avatar
              :id="`btn-cel-${data.item.idTrabajador}`"
              size="32"
              :variant="`light-info`"
            >
              <feather-icon
                icon="SmartphoneIcon"
              />
            </b-avatar>
          </b-link>
          <b-link
            v-if="data.item.facebook != '' && data.item.facebook != null"
            v-b-tooltip.hover.top="'Ir a Facebook'"
            :href="data.item.facebook"
            target="_blank"
            class="font-weight-bold ml-50"
          >
            <b-avatar
              :id="`btn-fb-${data.item.idTrabajador}`"
              size="32"
              :variant="`light-primary`"
            >
              <feather-icon
                icon="FacebookIcon"
              />
            </b-avatar>
          </b-link>
          <b-link
            v-if="data.item.instagram != '' && data.item.instagram != null"
            v-b-tooltip.hover.top="'Ir a Instagram'"
            :href="data.item.instagram"
            target="_blank"
            class="font-weight-bold ml-50"
          >
            <b-avatar
              :id="`btn-instagra,-${data.item.idTrabajador}`"
              size="32"
              :variant="`light-danger`"
            >
              <feather-icon
                icon="InstagramIcon"
              />
            </b-avatar>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(acciones)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="getEdit(data)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item @click="getDelete(data)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Eliminar</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="(data.item.cargo=='VENDEDOR CILINDROS' || data.item.cargo=='VENDEDOR GASOCENTRO' || data.item.cargo=='VENDEDOR GRANEL' || data.item.cargo=='VENDEDOR DEPOSITO')"
              @click="getSeries(data)"
            >
              <feather-icon icon="PlusIcon" />
              <span class="align-middle ml-50">Ver Series</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando {{ from }} a {{ to }} de {{ of }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="page"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <b-modal
      ref="my-modal-serie"
      hide-footer
      title=""
    >
      <div class="d-block">
        <h4 class="text-center">
          Series Asignadas</h4>
        <b-row class="mb-2 mt-2">
          <b-col
            cols="12"
            md="6"
            class="mb-1 mb-md-0"
          >
            <b-form-group
              label="Serie"
              label-for="serie"
            >
              <v-select
                v-model="tipoDocumento"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="descripcion"
                :options="selectTipoDocumento"
                class="xs"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-1 mb-md-0"
          >
            <b-form-group
              label="serie"
              label-for="serie"
            >
              <b-form-input
                v-model="serie"
                placeholder="Serie"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-button
              variant="success"
              class="btn-icon"
              type="submit"
              size="sm"
              :disabled="logSaveSerie"
              @click="agregarNewSerie()"
            >
              Agregar
              <feather-icon icon="SaveIcon" />
            </b-button>
          </b-col>
        </b-row>
        <div>
          <b-table
            ref="refUserListTableSerie"
            class="position-relative"
            :items="itemSerie"
            responsive
            :fields="fieldSerie"
            primary-key="serieId"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No se encontraron registros"
            :busy="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner
                  variant="primary"
                  class="align-middle"
                />
                <strong class="ml-1">Cargando...</strong>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="mt-3"
        variant="secondary"
        block
        @click="hideModal"
      >
        Cerrar
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BDropdown, BDropdownItem, BPagination, BMedia, BAvatar, BLink, VBTooltip, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import { showToast, showError } from '@/helpers'
import moment from 'moment'
import TrabajadorForm from './TrabajadorForm.vue'

export default {
  components: {
    TrabajadorForm,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BMedia,
    BAvatar,

    vSelect,
    BSpinner,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    isToggleSidebar: false,
    isBusy: false,
    dataEdit: {},
    formType: '',
    page: 1,
    perPage: 10,
    totalRows: 0,
    totalItems: 0,
    totalPage: 0,
    from: 0,
    to: 0,
    of: 0,
    pageOptions: [10, 20, 50],
    sortBy: null,
    sortDesc: false,
    sortDirection: 'asc',
    query: '',
    filterCargos: ['ADMINISTRATIVO', 'VENDEDOR', 'CHOFER'],
    filterCargoSel: '',
    items: [],
    fields: [
      { key: 'idTrabajador', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
      { key: 'nombre', label: 'Trabajador', sortable: true },
      { key: 'empresa', label: 'Empresa', sortable: true, thClass: 'text-center', tdClass: 'text-center' },
      { key: 'cargo', sortable: true },
      { key: 'facebook', label: 'Contacto', sortable: true },
      { key: 'serie', label: 'Series', sortable: true },
      { key: 'acciones' },
    ],
    itemSerie: [],
    fieldSerie: [
      { key: 'serieId', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
      { key: 'tipoDocumento.descripcion', label: 'Tipo de documento', sortable: true, thClass: 'text-center', tdClass: 'text-center' },
      { key: 'serie', label: 'Serie', sortable: true },
    ],
    tipoDocumento: {},
    serie: '',
    selectTipoDocumento: [],
    trabajadorSelected: {},
    logSaveSerie: false,
  }),
  watch: {
    perPage: {
      handler() {
        this.listarRegistros()
      },
    },
    page: {
      handler() {
        this.listarRegistros()
      },
    },
    query: {
      handler() {
        this.listarRegistros()
      },
    },
  },
  mounted() {
    this.listarRegistros()
  },
  methods: {
    async agregarNewSerie() {
      try {
        console.log(this.serie)
        if (this.serie === '') {
          throw new Error('TIENE AGREGAR UNA SERIE')
        }
        if (this.tipoDocumento === null) {
          throw new Error('TIENE QUE SELECIONAR UN TIPO DE DOCUMENTO')
        }
        console.log(this.tipoDocumento)
        console.log(this.trabajadorSelected)
        this.logSaveSerie = true
        const formSerie = { serieId: 0, almacen: { almacenId: 0, trabajadorId: this.trabajadorSelected.idTrabajador }, correlativo: 0, serie: this.serie, tipoDocumento: { tipoDocumentoId: this.tipoDocumento.tipoDocumentoId } }
        await store.dispatch('comercial/SERIE_CREATE', formSerie)
          .then(response => {
            console.log(response)
            this.showToast('Operación Extitosa', 'Seagrego la serie al trabajador', 'success')
            this.logSaveSerie = false
          }).catch(error => {
            throw new Error(error)
          })
        this.tipoDocumento = {}
        this.serie = ''
        await store.dispatch('comercial/GET_SERIES', { tipo_documento_id: 0, trabajador_id: this.trabajadorSelected.idTrabajador })
          .then(response => {
            this.itemSerie = response
          })
      } catch (error) {
        this.logSaveSerie = false
        console.log(error)
        console.log(error.message)
        console.log(error.errorMessage)
        this.showToast('Ha ocurrido un error', error.message, 'danger')
      }
    },
    getSeries(data) {
      this.trabajadorSelected = data.item
      Vue.swal({
        title: 'Obteniendo Series',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: async () => {
          Vue.swal.showLoading()
          await store.dispatch('comercial/TIPO_DOCUMENTO_FIND_ALL', {})
            .then(response => {
              this.selectTipoDocumento = response
            })
          await store.dispatch('comercial/GET_SERIES', { tipo_documento_id: 0, trabajador_id: this.trabajadorSelected.idTrabajador })
            .then(response => {
              this.itemSerie = response
              this.$refs['my-modal-serie'].show()
            })
          Vue.swal.close()
        },
      })
    },
    hideModal() {
      this.$refs['my-modal-serie'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal-serie'].toggle('#toggle-btn')
    },
    getImgUrl(val) {
      switch (val) {
        case 'GERENTE GENERAL':
          return require('@/assets/images/erp/iconos/cargo1.png')
        case 'GERENTE OPERACIONES':
          return require('@/assets/images/erp/iconos/cargo2.png')
        case 'GERENTE RRHH':
          return require('@/assets/images/erp/iconos/cargo3.png')
        case 'SUPERVISOR COMERCIAL ZONAL':
          return require('@/assets/images/erp/iconos/cargo4.png')
        case 'SUPERVISOR GRANEL':
          return require('@/assets/images/erp/iconos/cargo5.png')
        case 'COORDINADOR OSINERGMIN':
          return require('@/assets/images/erp/iconos/cargo6.png')
        case 'CAJERA':
          return require('@/assets/images/erp/iconos/cargo7.png')
        case 'CONTADOR':
          return require('@/assets/images/erp/iconos/cargo8.png')
        case 'JEFE DEPOSITO GASOCENTRO':
          return require('@/assets/images/erp/iconos/cargo9.png')
        case 'JEFE PLATAFORMA':
          return require('@/assets/images/erp/iconos/cargo10.png')
        case 'CONDUCTOR TRANSPORTE PRIMARIO':
          return require('@/assets/images/erp/iconos/cargo11.png')
        case 'CONDUCTOR TRANSPORTE SECUNDARIO':
          return require('@/assets/images/erp/iconos/cargo12.png')
        case 'VENDEDOR GRANEL':
          return require('@/assets/images/erp/iconos/cargo13.png')
        case 'VENDEDOR CILINDROS':
          return require('@/assets/images/erp/iconos/cargo14.png')
        case 'VENDEDOR DEPOSITO':
          return require('@/assets/images/erp/iconos/cargo15.png')
        case 'VENDEDOR GASOCENTRO':
          return require('@/assets/images/erp/iconos/cargo16.png')
        case 'VIGILANTE':
          return require('@/assets/images/erp/iconos/cargo17.png')
        case 'DELIVERISTA':
          return require('@/assets/images/erp/iconos/cargo18.png')
        default:
          return require('@/assets/images/erp/iconos/cargo1.png')
      }
    },
    getEdad(value) {
      const hoy = moment(new Date())
      const fechaString = moment(String(value), 'DD/MM/YYYY').format('MM/DD/YYYY')
      const fecha = moment(fechaString, 'MM/DD/YYYY')
      return `${hoy.diff(moment(String(fecha)).format('MM/DD/YYYY'), 'years')} años`
    },
    getColorAvatar() {
      const max = 7
      const min = 1
      const status = Math.floor(Math.random() * ((max + 1) - min) + min)
      if (status === 1) return 'primary'
      if (status === 2) return 'danger'
      if (status === 3) return 'secondary'
      if (status === 4) return 'warning'
      if (status === 5) return 'info'
      if (status === 6) return 'success'
      return 'primary'
    },
    avatarText,
    async listarRegistros() {
      this.isBusy = true
      await store
        .dispatch('rrhh/TRABAJADOR_FIND_ALL', {
          cargo: '',
          limit: this.perPage,
          query: this.query,
          page: this.page,
          sortBy: 'idTrabajador%7CDESC',
          tipo: 'grilla',
        })
        .then(response => {
          this.items = response.items
          this.totalRows = response.totalRows
          this.totalItems = response.totalItems
          this.totalPage = response.totalPage
          this.from = this.perPage * (this.page - 1) + (this.totalRows ? 1 : 0)
          this.to = this.perPage * (this.page - 1) + this.totalRows
          this.of = this.totalItems
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
        })
      this.isBusy = false
    },
    openForm(type) {
      this.formType = type
      this.isToggleSidebar = true
    },
    getEdit(data) {
      this.formType = 'edit'
      this.isToggleSidebar = true
      this.dataEdit = data.item
    },
    getDelete(data) {
      this.$swal({
        title: 'Está seguro?',
        text: 'Va eliminar un registro!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store
            .dispatch('rrhh/TRABAJADOR_DELETE', {
              id: data.item.idTrabajador,
            })
            .then(response => {
              this.listarRegistros()
              this.showToast('Operación Extitosa', 'Ha Eliminado correctamente el registro.', 'success')
              return response
            })
            .catch(error => {
              this.showToast('Ha ocurrido un error', error, 'danger')
            })
        }
      })
    },
    refetchData(response) {
      let message = 'El proceso se ha ejecutado satisfactoriamente.'
      if (response.message) {
        message = response.message
      }
      this.showToast('Operación Extitosa', message, 'success')
      this.listarRegistros()
    },
    showToast,
    showError,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
