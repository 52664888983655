<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isToggleSidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => toggleSidebar(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titleForm }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Form -->
      <b-form
        class="p-2"
        @reset.prevent="resetForm"
      >
        <h6
          class="text-info"
        >
          <feather-icon icon="AlertCircleIcon" />
          <span class="align-middle ml-25">Datos Generales</span>
        </h6>

        <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

        <b-form-group
          label="Documento de Identidad"
          label-for="nroDoc"
        >
          <b-input-group class="input-group-merge">
            <v-select
              id="frmTipoDoc"
              v-model="tipoDocSel"
              label="abreviatura"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tiposDocument"
              :clearable="false"
              @input="isReadOnly()"
            />
            <b-form-input
              id="search-nro-doc"
              v-model="dataTrabajador.documento"
              style="padding: 0.438rem 1rem;"
            />
            <b-button
              variant="info"
              @click="searchTrabajador"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
          </b-input-group>
        </b-form-group>

        <div
          v-if="isBusy"
          class="text-center text-primary my-2"
        >
          <b-spinner
            variant="primary"
            class="align-middle"
          />
          <strong class="ml-1">Cargando...</strong>
        </div>

        <b-media class="mb-0">
          <template #aside>
            <b-avatar
              ref="previewEl"
              :src="`data:image/jpeg;base64,${fotoReniec}`"
              size="132px"
              rounded
              style="width: 132px; height: 208px;"
            />
          </template>

          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Nombres"
                label-for="nombre"
              >
                <b-form-input
                  id="nombre"
                  v-model="dataTrabajador.nombre"
                  :readonly="isReadOnly()"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Apellido Paterno"
                label-for="apellido_paterno"
              >
                <b-form-input
                  id="apellido_paterno"
                  v-model="dataTrabajador.apellidoPaterno"
                  :readonly="isReadOnly()"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Apellido Materno"
                label-for="apellido_materno"
              >
                <b-form-input
                  id="apellido_materno"
                  v-model="dataTrabajador.apellidoMaterno"
                  :readonly="isReadOnly()"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-media>

        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Fecha Nacimiento"
              label-for="fecha_nacimiento"
            >
              <flat-pickr
                id="fecha_nacimiento"
                v-model="dataTrabajador.fechaNacimiento"
                class="form-control"
                :config="configDP"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Sexo"
              label-for="sexo"
            >
              <v-select
                id="sexo"
                v-model="sexoSel"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="sexos"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Talla Zapato"
              label-for="tallaZapato"
            >
              <v-select
                id="tallaZapato"
                v-model="tallaZapatoSel"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tallasZapato"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Talla Polo"
              label-for="tallaPolo"
            >
              <v-select
                id="tallaPolo"
                v-model="tallaPoloSel"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tallasPolo"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Talla Pantalón"
              label-for="tallaPantalon"
            >
              <v-select
                id="tallaPantalon"
                v-model="tallaPantalonSel"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tallasPantalon"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Licencia de Conducir"
              label-for="licencia"
            >
              <v-select
                id="licencia"
                v-model="licenciaSel"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="licencias"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <h6
          class="text-info"
        >
          <feather-icon icon="BriefcaseIcon" />
          <span class="align-middle ml-25">Datos Laborales</span>
        </h6>

        <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

        <b-form-group
          label="Empresa"
          label-for="empresa"
        >
          <v-select
            id="empresa"
            v-model="empresaSel"
            label="razonSocial"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="empresas"
            :clearable="false"
          />
        </b-form-group>

        <b-form-group
          label="Fecha Ingreso"
          label-for="fecha_ingreso"
        >
          <flat-pickr
            v-model="dataTrabajador.fechaIngreso"
            class="form-control"
            :config="configDP"
          />
        </b-form-group>

        <!-- Cargo -->
        <b-form-group
          label="Cargo"
          label-for="cargo"
        >
          <v-select
            id="cargo"
            v-model="cargoSel"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="cargos"
            :clearable="false"
          />
        </b-form-group>

        <!-- Salario -->
        <b-form-group
          label="Salario"
          label-for="salario"
        >
          <b-form-input
            id="salario"
            v-model="dataTrabajador.salario"
            autofocus
            trim
            placeholder=""
          />
        </b-form-group>

        <!-- Asignación Familiar -->
        <b-form-group
          label="Asignación Familiar"
          label-for="asignacionFamiliar"
        >
          <v-select
            id="asignacionFamiliar"
            v-model="asignacionFamiliarSel"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="asignacionesFamiliares"
            :clearable="false"
          />
        </b-form-group>

        <h6
          class="text-info"
        >
          <feather-icon icon="PhoneCallIcon" />
          <span class="align-middle ml-25">Datos de Contacto</span>
        </h6>

        <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Celular"
              label-for="celular"
            >
              <b-form-input
                id="celular"
                v-model="dataTrabajador.celular"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Teléfono"
              label-for="telefono"
            >
              <b-form-input
                id="telefono"
                v-model="dataTrabajador.telefono"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Correo"
              label-for="correo"
            >
              <b-form-input
                id="correo"
                v-model="dataTrabajador.correo"
                type="email"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <h6
          class="text-info"
        >
          <feather-icon icon="FacebookIcon" />
          <span class="align-middle ml-25">Redes Sociales</span>
        </h6>

        <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

        <b-form-group
          label="Facebook"
          label-for="facebook"
        >
          <b-form-input
            id="facebook"
            v-model="dataTrabajador.facebook"
            type="url"
          />
        </b-form-group>

        <b-form-group
          label="Instagram"
          label-for="instagram"
        >
          <b-form-input
            id="instagram"
            v-model="dataTrabajador.instagram"
            type="url"
          />
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="button"
            @click="saveForm"
          >
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancelar
          </b-button>
        </div>

      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BRow, BCol, BAvatar, BMedia, BSidebar, BForm, BFormGroup, BFormInput, BInputGroup, BButton, BSpinner,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
// import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    BMedia,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BButton,
    flatPickr,
    vSelect,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isToggleSidebar',
    event: 'update:is-toggle-sidebar',
  },
  props: {
    isToggleSidebar: {
      type: Boolean,
      required: true,
    },
    dataEdit: {
      type: Object,
      required: false,
      default: null,
    },
    formType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const titleForm = ref('')
    const isBusy = ref(false)
    const tiposDocument = ref([])
    const tipoDocSel = ref(null)
    const empresas = ref([])
    const empresaSel = ref(null)
    const dataTrabajador = ref({})
    const fotoReniec = ref('')
    const cargos = ref([
      'ASISTENTE COMERCIAL',
      'ASISTENTE CONTABLE',
      'ASISTENTE GERENCIA',
      'CAJERA DEPÓSITO',
      'CHOFER CISTERNA',
      'CHOFER ENVASADO',
      'CHOFER GRANEL',
      'ENVASADO',
      'ESTIBA',
      'GERENTE COMERCIAL',
      'GERENTE OPERACIONES',
      'JEFE DE PLANTA',
      'JEFE DE PLATAFORMA',
      'JEFE GRANEL',
      'JEFE SCOOP',
      'JEFE TESORERÍA',
      'LIMPIEZA',
      'LOGO',
      'PINTADO',
      'PROBADO',
      'REPARACIÓN',
      'REPARTIDOR',
      'REPESADO',
      'SUPERVISOR TRANSPORTES',
      'TARA',
      'TESORERÍA',
      'VENDEDOR CILINDROS',
      'VENDEDOR GASOCENTRO',
      'VENDEDOR GRANEL',
      'VENDEDOR DEPOSITO',
      'VIGILANTE'])
    const cargoSel = ref(null)
    const asignacionesFamiliares = ref(['SI', 'NO'])
    const asignacionFamiliarSel = ref(null)
    const sexos = ref(['MASCULINO', 'FEMENINO'])
    const sexoSel = ref('MASCULINO')
    const readonlyFrm = ref(false)
    const tallasZapato = ref([35, 36, 37, 38, 39, 40, 41, 42, 43, 44])
    const tallaZapatoSel = ref(null)
    const tallasPolo = ref(['XS', 'S', 'M', 'L', 'XL', 'XXL'])
    const tallaPoloSel = ref(null)
    const tallasPantalon = ref([26, 28, 30, 32, 34, 36, 38])
    const tallaPantalonSel = ref(null)
    const licencias = ref(['A-I', 'A-IIa', 'A-IIb', 'A-IIIa', 'A-IIIb', 'A-IIIc', 'B-I', 'B-IIa', 'B-IIb', 'B-IIc'])
    const licenciaSel = ref(null)

    const configDP = ref(
      {
        enableTime: false,
        dateFormat: 'd/m/Y',
        altInput: true,
        altFormat: 'd/m/Y',
      },
    )

    const resetForm = () => {
      dataTrabajador.value = {}
      fotoReniec.value = ''
    }

    const getTiposDocumento = async () => {
      await store.dispatch('catalogo/TIPO_DOCUMENTO_FIND_ALL')
        .then(response => {
          if (response) {
            tiposDocument.value = response
            tipoDocSel.value = tiposDocument.value.find(id => id.idTipoDocumento === 1)
          }
        })
    }

    const getEmpresas = async () => {
      await store.dispatch('catalogo/EMPRESA_FIND_ALL', {
        tipo: 'cmb',
        page: 1,
        limit: 100,
        query: '',
        sortBy: 'idEmpresa%7CDESC',
      })
        .then(response => {
          if (response) {
            empresas.value = response
          }
        })
    }

    const searchTrabajador = async () => {
      isBusy.value = true
      await store
        .dispatch('helpers/RENIEC_FIND_CIUDADANO', {
          dni: dataTrabajador.value.documento,
        })
        .then(response => {
          if (response.datosPersona) {
            dataTrabajador.value.nombre = response.datosPersona.prenombres
            dataTrabajador.value.apellidoPaterno = response.datosPersona.apPrimer
            dataTrabajador.value.apellidoMaterno = response.datosPersona.apSegundo
            dataTrabajador.value.foto = response.datosPersona.foto
            dataTrabajador.value.direccionReniec = response.datosPersona.direccion
            dataTrabajador.value.ubigeoReniec = response.datosPersona.ubigeo
            fotoReniec.value = response.datosPersona.foto
          }
          isBusy.value = false
        })
        .catch(error => {
          isBusy.value = false
          emit('error-data', error)
        })

      /* isBusy.value = true
      await store
        .dispatch('gsescalafon/DIRESA_FIND_PACIENTE', {
          documento: dataTrabajador.value.numeroDoc,
          tipodoc: tipoDocSel.value.id,
        })
        .then(response => {
          if (response.data.datos) {
            dataTrabajador.value.fechaNacimiento = response.data.datos[0].fecha_nacimiento
            if (response.data.datos[0].sexo === '1') {
              dataTrabajador.value.sexo = 'MASCULINO'
            } else {
              dataTrabajador.value.sexo = 'FEMENINO'
            }
          } else {
            dataTrabajador.value.fechaNacimiento = '01/01/1990'
            dataTrabajador.value.sexo = 'NO REGISTRADO'
          }
          isBusy.value = false
        })
        .catch(error => {
          isBusy.value = false
          emit('error-data', error)
        }) */
    }

    const saveForm = async () => {
      // Validar datos antes
      if (empresaSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar la Empresa a la que pertenece el Trabajador.' } } })
        return
      }
      if (cargoSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar Cargo del Trabajador.' } } })
        return
      }
      if (sexoSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar Sexo del Trabajador.' } } })
        return
      }
      if (asignacionFamiliarSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar Asignación Familiar del Trabajador.' } } })
        return
      }
      if (tallaZapatoSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar Talla de Zapato del Trabajador.' } } })
        return
      }
      if (tallaPoloSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar Talla de Polo del Trabajador.' } } })
        return
      }
      if (tallaPantalonSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar Talla de Pantalón del Trabajador.' } } })
        return
      }

      let service = 'rrhh/TRABAJADOR_CREATE'
      if (props.formType === 'edit') {
        service = 'rrhh/TRABAJADOR_UPDATE'
      }

      // dataTrabajador.value.foto = fotoReniec.value
      dataTrabajador.value.tipoDocumento = tipoDocSel.value
      // dataTrabajador.value.fechaNacimiento = moment(String(dataTrabajador.value.fechaNacimiento), 'DD/MM/YYYY').format('YYYY-MM-DD')
      // dataTrabajador.value.fechaIngreso = moment(String(dataTrabajador.value.fechaIngreso), 'DD/MM/YYYY').format('YYYY-MM-DD')
      dataTrabajador.value.cargo = cargoSel.value
      dataTrabajador.value.sexo = sexoSel.value
      dataTrabajador.value.empresa = empresaSel.value
      dataTrabajador.value.asignacionFamiliar = asignacionFamiliarSel.value
      dataTrabajador.value.tallaZapato = tallaZapatoSel.value
      dataTrabajador.value.tallaPolo = tallaPoloSel.value
      dataTrabajador.value.tallaPantalon = tallaPantalonSel.value

      await store.dispatch(service, dataTrabajador.value)
        .then(response => {
          emit('refetch-data', response)
          emit('update:is-toggle-sidebar', false)
        })
        .catch(error => {
          emit('error-data', error)
          emit('update:is-toggle-sidebar', false)
        })
    }

    const isReadOnly = () => {
      if (tipoDocSel.value && tipoDocSel.value.id === 1) return true
      return false
    }

    const toggleSidebar = async val => {
      emit('update:is-toggle-sidebar', val)
      resetForm()
      getTiposDocumento()
      getEmpresas()
      if (props.formType === 'new') {
        titleForm.value = 'Agregar Trabajador'
      }
      if (props.formType === 'edit') {
        dataTrabajador.value = {
          idTrabajador: props.dataEdit.idTrabajador,
          nombre: props.dataEdit.nombre,
          apellidoPaterno: props.dataEdit.apellidoPaterno,
          apellidoMaterno: props.dataEdit.apellidoMaterno,
          cargo: props.dataEdit.cargo,
          celular: props.dataEdit.celular,
          telefono: props.dataEdit.telefono,
          correo: props.dataEdit.correo,
          direccionReniec: props.dataEdit.direccionReniec,
          direccionResidencia: props.dataEdit.direccionResidencia,
          documento: props.dataEdit.documento,
          facebook: props.dataEdit.facebook,
          instagram: props.dataEdit.instagram,
          foto: props.dataEdit.foto,
          sexo: props.dataEdit.sexo,
          ubigeoReniec: props.dataEdit.ubigeoReniec,
          ubigeoResidencia: props.dataEdit.ubigeoResidencia,
          fechaIngreso: props.dataEdit.fechaIngreso,
          fechaNacimiento: props.dataEdit.fechaNacimiento,
          fechaCese: props.dataEdit.fechaCese,
          salario: props.dataEdit.salario,
          tallaZapato: props.dataEdit.tallaZapato,
          tallaPolo: props.dataEdit.tallaPolo,
          tallaPantalon: props.dataEdit.tallaPantalon,
          licencia: props.dataEdit.licencia,
          estado: props.dataEdit.estado,
        }
        fotoReniec.value = props.dataEdit.foto
        cargoSel.value = props.dataEdit.cargo
        asignacionFamiliarSel.value = props.dataEdit.asignacionFamiliar
        sexoSel.value = props.dataEdit.sexo
        tipoDocSel.value = props.dataEdit.tipoDocumento
        empresaSel.value = props.dataEdit.empresa
        tallaZapatoSel.value = props.dataEdit.tallaZapato
        tallaPoloSel.value = props.dataEdit.tallaPolo
        tallaPantalonSel.value = props.dataEdit.tallaPantalon
        licenciaSel.value = props.dataEdit.licencia
        titleForm.value = 'Editar Trabajador'
      }
    }

    return {
      titleForm,
      isBusy,
      tiposDocument,
      tipoDocSel,
      empresas,
      empresaSel,
      dataTrabajador,
      searchTrabajador,
      fotoReniec,
      saveForm,
      toggleSidebar,
      isReadOnly,
      cargos,
      cargoSel,
      asignacionFamiliarSel,
      asignacionesFamiliares,
      sexos,
      sexoSel,
      resetForm,
      readonlyFrm,
      configDP,
      tallaZapatoSel,
      tallasZapato,
      tallaPoloSel,
      tallasPolo,
      tallaPantalonSel,
      tallasPantalon,
      licenciaSel,
      licencias,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
